import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Approximately 1 in 5 people in the UK have a disability or impairment that impacts their daily life.`}</p>
    <p>{`But accessible design is not just about disability. It benefits all users by striving for simple, usable and inclusive experiences.`}</p>
    <GridList columns="2" mdxType="GridList">
  <GridListItem title="Websites" titleElement="h2" linkText="Accessibility standards for web" linkTo="/accessibility/accessibility-standards-for-web" border={true} mdxType="GridListItem">
    A guide to help design and build accessible web experiences.
  </GridListItem>
  <GridListItem title="iOS and Android apps" titleElement="h2" linkText="Accessibility standards for iOS and Android" linkTo="/accessibility/accessibility-standards-for-native" border={true} mdxType="GridListItem">
    A guide to help design and build accessible iOS and Android experiences.
  </GridListItem>
  <GridListItem title="Emails" titleElement="h2" linkText="Accessibility testing guidance" linkTo="/accessibility/accessibility-standards-email" border={true} mdxType="GridListItem">
    A guide to help design and build accessible emails.
  </GridListItem>
  <GridListItem title="Testing" titleElement="h2" linkText="Accessibility testing guidance" linkTo="/accessibility/testing-for-accessibility" border={true} mdxType="GridListItem">
    A guide to help you test the accessibility of your products.
  </GridListItem>
    </GridList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      